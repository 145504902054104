import React from "react"
import Style from "../styles/friendlink.module.css"

const links = {
    index: [
        {
            url: "https://like-gif.com",
            keyWord: "妹子gif图片"
        },
        {
            url: "https://52haha.xyz",
            keyWord: "搞笑段子"
        }
    ],
    global: [
        {
            url: "https://like-gif.com",
            keyWord: "妹子gif图片"
        },
        {
            url: "https://52haha.xyz",
            keyWord: "搞笑段子"
        }
    ]
    
}

export default (props) => (
    <div className={Style.friend}>
        <div>
            <p><span>友情链接</span></p>
            {
                links[props.urlType].map((val, index) => (
                <a href={val.url} key={index} target="_blank">{val.keyWord}</a>
                ))
            }
        </div>
    </div>
)
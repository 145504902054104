import React from "react"
import Header from "./header"
import Main from "./main"
import Footer from "./footer"

export default (props) => (
    <div style={{backgroundColor:`#f1f1f1`}}>
        <Header />
        <Main>
            {props.children}
        </Main>
        {props.friendLink}
        <Footer />
    </div>
)
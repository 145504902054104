import React from "react"
import Style from "../styles/header.module.css"

export default () => (
    <header>
        <div className={Style.header}>
            <a href="https://1-2-3.vip">首页</a>
            <a href="https://like-gif.com">妹子gif</a>
        </div>
    </header>
)
import React from "react"
import LazyLoad from 'react-lazy-load'
import Style from "../styles/item.module.css"
import { Link } from "gatsby"

const colurs = ['#0000d0','#a7549a','#f86927','#c89b40','#317ef3','#fe8081','#f8cc89','#386ba0','#010101','#a88464',
'#bf242c','#8f75a4','#88dfc5','#f88fd7','#b6a6ed','#f3eaed','#d1a2d9'];

class Item extends React.Component {
    constructor(props) {
        super(props)
    }

    render(){
        return (
            <figure className={Style.figure} style={{width:this.props.itemWidth}}>
            <Link to={`/pic/${this.props.itemId}`}>
                <div className={Style.imgBox} style={{background: colurs[Math.floor((Math.random()*colurs.length))]}}>
                    <LazyLoad height={this.props.itemImgHeight || 300} onContentVisible={() => {
                        if(this.props.isLast) {
                            this.props.nextImgs()
                        }
                    }}>
                        <img 
                        onError={(e) => {e.target.onerror = null;e.target.src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"}}
                        style={{height:this.props.itemImgHeight || 300}}            
                        src={`${this.props.itemSrc}`} 
                        alt={this.props.itemTitle}/>
                    </LazyLoad>
                    <span className={Style.imgNum}>{this.props.itemLen} 图</span>
                </div>
            </Link>
            <figcaption>{this.props.itemTitle}</figcaption>
    </figure>
        )
    }
}

export default Item